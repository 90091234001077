import { Box } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const calculateweight = (duration)=>{
  const startSeconds = duration.startingTime.seconds;
  const endSeconds = duration.closingTime.seconds;

  // Calculate the duration in seconds between the two times
  const durationInSeconds = endSeconds - startSeconds;

  // Total seconds in 24 hours
  const totalSecondsInADay = 24 * 60 * 60;

  // Calculate the percentage
  const percentage = (durationInSeconds / totalSecondsInADay) * 100;
  if(percentage<20)
  {
    return 20
  }else{
    return percentage.toFixed(0);
  }
  
}
// Define a small Header component
const Oncallactivity = ({

    name,
    duration,
    color,
    type,
    line = true,
    date,
    index,
    weight = 100
  }) => {
    const [isHovered, setIsHovered] = useState("");
    const {t} = useTranslation()
    weight = calculateweight(duration)
    // name=="GARDE" && console.log("weight ===",weight,duration)
    return (
      
      <Box
        className="event"
        sx={{
          borderColor: color,
          color: color,
          height:weight+"%",
          maxHeight:"100%"
        }}
      >
        <Box>
          {" "}
          <label> {name}</label>
          <span>
            {" "}
            {moment.unix(duration.startingTime.seconds).format("HH:mm") +
              "-" +
              moment.unix(duration.closingTime.seconds).format("HH:mm")}{" "}
            | ({t("on call")})
          </span>
        </Box>
  
        <Box
          className="bg"
            sx={{
              background: color,
              height:`100% !important`
            }}
        ></Box>
      </Box>
    );
  };

const Otheractivity = ({
  name,
  duration,
  start,
  end,
  color,
  type,
  line = true,
 
  date,
  index,
  activity
}) => {
  const [isHovered, setIsHovered] = useState("");
  const {t} = useTranslation()
  return (
    <>
    {(activity.expand!==undefined && activity.expand )?
      <Oncallactivity
              name={activity.activityName}
              duration={activity.duration}
              color={activity.color}
              type={activity.activityType?activity.activityType:"oc"}
              date={date}
              index={0}
              weight = {activity.weight}
            />
      :<div
      className="bar active"
      style={{
        background: color,
        color: color,
      }}
      onMouseEnter={(e) => {
        setIsHovered(date + "oc" + index);
      }}
      onMouseLeave={(e) => {
        setIsHovered("");
      }}
    >
      <div
        className={
          "hover-bar " + (isHovered == date + "oc" + index ? "active" : "")
        }
        
      >
        <Box
          className="event"
          sx={{
            borderColor: color,
            color: color,
          }}
        >
          <Box>
            <label>{name}</label>
            <span>
              {" "}

              {(start + " - "+end)}
              {/* {moment.unix(duration.startingTime.seconds).format("HH:mm") +
                "-" +
                moment.unix(duration.closingTime.seconds).format("HH:mm")} */}
                {" "}
              | ({t("on call")})
            </span>
          </Box>
          <Box
        className="bg"
        sx={{
          background: color,
        }}
      ></Box>
        </Box>
      </div>
    </div>}
    </>
  );
};

const Activity = ({ name, duration, color, type, line = true,clinicClose,contd }) => {
  const {t} = useTranslation()
  return (
    <Box
      className="event"
      sx={{
        borderColor: color,
        color: color,
        // ...(contd && {
        //   width: '100%',
        //   height: '40%'
        // })
      }}
     
    >
     {!contd? <Box>
        {" "}
        <label> {name}</label>
        <span>
          {" "}
          {moment.unix(duration.startingTime.seconds).format("HH:mm") +
            "-" +
            moment.unix(duration.closingTime.seconds).format("HH:mm")}{" "}
          {/* | ({(type == "mor" && t("Morning")) || (type == "aft" && t("Afternoon"))}) */}
        </span>
      </Box>:
      <Box className="blank-text"

      >
         <label> blank-text</label>
        <span>
         text123
        </span>
    
    </Box>}

      <Box
        className="bg"
        sx={{
          background: color,
        }}
      ></Box>
    </Box>
  );
};
const Blankctivity = ({ name }) => {
  return (
    <Box className="event blank" >
      <Box>
        <label> {name}</label>
        <span>
         text
        </span>
      </Box>

      <Box
        className="bg"
      ></Box>
    </Box>
  );
};
const Blanktheractivity = ({
  name,

}) => {

  return (
    
    <div
    className="bar active blank"
 />
  
  );
};
const Leaveactivity = ({ name, duration, color, type, line = true }) => {
    const {t} = useTranslation()
  return (
    type ==0 && <Box className="leave full">
      <label>{t("Scheduler1.leave")}</label>
      <span> {t("Full day")}</span>
    </Box> || 
     type ==1 && <Box className="leave">
     <label>{t("Scheduler1.leave")}</label>
     <span> {t("Morning")}</span>
   </Box> ||
    type ==2 && <Box className="leave">
    <label>{t("Scheduler1.leave")}</label>
    <span> {t("Afternoon")}</span>
  </Box>
  );
};

const Activitycell = ({ schedule, date ,holiday ,clinicClose,clinic}) => {
  holiday = holiday || []
  var records = [];
  var leave = false;
  var leaveType = 0;
  var slot = [
    {norecord:true,oc:true},
    {norecord:true,oc:false},
    {norecord:true,oc:false},
    {norecord:true,oc:true},
    {norecord:true,oc:false},
    {norecord:true,oc:false},
    {norecord:true,oc:true}
  ]
  var specialActivity = false
  // console.log(clinic,"morningslot")
  var morningslot = clinic.time_slot_morning[moment(date,"YYYY-MM_DD").format("dddd")]
  var afternoonslot = clinic.time_slot_afternoon[moment(date,"YYYY-MM_DD").format("dddd")]

  const checktime = (activity)=>{
    const start =moment(moment.unix(activity.duration.startingTime.seconds).format('HH:mm'),"HH:mm");
  const end = moment(moment.unix(activity.duration.closingTime.seconds).format('HH:mm'),"HH:mm");
  // Calculate the difference in hours
  const duration = moment.duration(end.diff(start));
  const hours = duration.asHours();

  // Check if the duration is more than 12 hours
  return hours > 12;
  }
  const timeDiff = (activity)=>{
    const start =moment(moment.unix(activity.duration.startingTime.seconds).format('HH:mm'),"HH:mm");
  const end = moment(moment.unix(activity.duration.closingTime.seconds).format('HH:mm'),"HH:mm");
  // Calculate the difference in hours
  const duration = moment.duration(end.diff(start));
  const hours = duration.asHours();

  // Check if the duration is more than 12 hours
  return hours
  }
  // console.log("schedule === ",schedule[date],date)
  if (
    schedule[date] &&
    schedule[date].activityM &&
    schedule[date].activitySP &&
    schedule[date].activityAf
  ) {
    leave = schedule[date]?.leave
    specialActivity = schedule[date]?.specialActivity
    leaveType = schedule[date]?.leaveValue

    records = [
      ...schedule[date].activityM,
      ...schedule[date].activitySP,
      ...schedule[date].activityAf,
    ];
    records.sort((a, b) => {
      let [hoursA, minutesA] = moment
        .unix(a.duration.startingTime.seconds)
        .format("HH:mm")
        .split(":")
        .map(Number);
      let [hoursB, minutesB] = moment
        .unix(b.duration.startingTime.seconds)
        .format("HH:mm")
        .split(":")
        .map(Number);

      if (hoursA !== hoursB) {
        return hoursA - hoursB;
      } else {
        return minutesA - minutesB;
      }
    });
  
    var mor = 1
    var aft = 4
    var oncM = 0
    var oncl = 3
    var onca = 6
    records.map((da,ind)=>{
      // console.log("activity ",da)
      if((!da.rate.onCall &&  !da.rate.onCallIn && !da.rate.onDuty))
      {
      if(moment(morningslot.closing_time,"HH:mm").isValid)
    {  if(moment(da.start,"HH:mm").isSameOrBefore(moment(morningslot.closing_time,"HH:mm")))
        {
          if(da.visualization)
         { if(da.visualization?.firstHalf && da.visualization?.secondHalf)
            { 
              var da2 = JSON.parse(JSON.stringify(da))
              da2.continue = true
             slot[mor] = da
            //  var da2 = JSON.parse(JSON.stringify(da))
            //  da2.continue = true
             slot[mor+1] = da2
            }else
          if(da.visualization?.firstHalf)
            { 
             slot[mor] = da

            }else if(da.visualization?.secondHalf){
             slot[mor+1] = da
            }}else{
              slot[mor] = da
            }
             
        }
      }
      if(moment(afternoonslot.opening_time,"HH:mm").isValid)
        {  if(moment(da.start,"HH:mm").isSameOrAfter(moment(afternoonslot.opening_time,"HH:mm")))
            {
              if(da.visualization)
                { if(da.visualization?.firstHalf && da.visualization?.secondHalf)
                   { 
                    var da2 = JSON.parse(JSON.stringify(da))
                    da2.continue = true
                    slot[aft] = da
               
                    slot[aft+1] = da2
                   }else
                 if(da.visualization?.firstHalf)
                   { 
                    slot[aft] = da
       
                   }else if(da.visualization?.secondHalf){
                    slot[aft+1] = da
                   }}else{
                     slot[aft] = da
                   }
            }
          }
        }

          if(((da.rate.onCall || da.rate.onCallIn || da.rate.onDuty) && !(holiday.includes(date))))
            { 

              if(moment(afternoonslot.opening_time,"HH:mm").isValid() && moment(morningslot.opening_time,"HH:mm").isValid() )
              {
                if(moment(moment.unix(da.duration.startingTime.seconds).format('HH:mm'),"HH:mm").isSameOrAfter(moment(afternoonslot.closing_time,"HH:mm")))
                  {
                    slot[onca] = da
                  }else if(moment(moment.unix(da.duration.startingTime.seconds).format('HH:mm'),"HH:mm").isSameOrBefore(moment(morningslot.opening_time,"HH:mm")))
                    {
                      // this condition have to be replacedd by  slot[oncM] = da
                      if(da.duration.timeBreakup.length==1 )
                      {
                        slot[oncM] = da
                      }
                     


                    }else{
                      slot[oncl] = da
                    }
              }else{
              
                  if(!moment(afternoonslot.opening_time,"HH:mm").isValid())
                  {
                    // console.log(moment(date,"YYYY-MM_DD").format("dddd"),da)
                    if(moment(moment.unix(da.duration.startingTime.seconds).format('HH:mm'),"HH:mm").isSameOrBefore(moment(morningslot.opening_time,"HH:mm")))
                      {
                        // slot[oncM] = da
                        // this condition need to be removed if oc is needed to change
                        if(da.duration.timeBreakup.length==1 )
                          {
                            slot[oncM] = da
                          }
                      } else  if(moment(moment.unix(da.duration.startingTime.seconds).format('HH:mm'),"HH:mm").isSameOrAfter(moment(morningslot.closing_time,"HH:mm")))
                        {
                          // console.log(moment(date,"YYYY-MM_DD").format("dddd"),da)
                          if(timeDiff(da)>2)
                            {
                              var da2= JSON.parse(JSON.stringify(da))
                            da2.expand = true
                            da2.weight = "40"
                            slot[oncl] = da2

                            }else{
                              if(moment(moment.unix(da.duration.startingTime.seconds).isAfter(moment("19:00","HH:mm"))))
                              {
                                slot[onca] = da
                              }else{
                                slot[oncl] = da
                              }

                              
                              
                            }
                        }
                  }
                  if(!moment(morningslot.opening_time,"HH:mm").isValid())
                    {
                      if(moment(moment.unix(da.duration.startingTime.seconds).format('HH:mm'),"HH:mm").isSameOrAfter(moment(afternoonslot.closing_time,"HH:mm")))
                        {
                          slot[onca] = da
                        } else  if(moment(moment.unix(da.duration.startingTime.seconds).format('HH:mm'),"HH:mm").isSameOrBefore(moment(afternoonslot.opening_time,"HH:mm")))
                          {
                            if(timeDiff(da)>2)
                            {
                              var da2= JSON.parse(JSON.stringify(da))
                            da2.expand = true
                            da2.weight = "50"
                            slot[oncM] = da2

                            }else{
                              slot[oncl] = da
                            }
                            
                          }
                    }
                    if((!moment(afternoonslot.opening_time,"HH:mm").isValid() && !moment(morningslot.opening_time,"HH:mm").isValid()))
                    {
                      
                      if(moment(moment.unix(da.duration.startingTime.seconds).format('HH:mm'),"HH:mm").isSameOrAfter(moment("17:00","HH:mm")))
                        {
                          slot[onca] = da
                        }else if(moment(moment.unix(da.duration.startingTime.seconds).format('HH:mm'),"HH:mm").isSameOrBefore(moment("11:00","HH:mm")))
                          {
                            slot[oncM] = da
                          }else{
                            slot[oncl] = da
                          }
                    }

              }

            
             
              }    
              if(holiday.includes(date) && (da.rate.onCall || da.rate.onCallIn || da.rate.onDuty))
                { 
                          if(moment(moment.unix(da.duration.startingTime.seconds).format('HH:mm'),"HH:mm").isSameOrAfter(moment("17:00","HH:mm")))
                            {
                              slot[onca] = da
                            }else if(moment(moment.unix(da.duration.startingTime.seconds).format('HH:mm'),"HH:mm").isSameOrBefore(moment("11:00","HH:mm")))
                              {
                                slot[oncM] = da
                              }else{
                                slot[oncl] = da
                              }
                        
    
                  
    
                
                 
                  }    

    })
    // console.log("slots === ",slot)

  }
 

  return (
    <>
 
      {slot.map((r, ri) => (
        <>
        
        {(r.norecord!== undefined && r.norecord==true)?
        <>

           {r.oc ?<Blanktheractivity name={ri}/> : <Blankctivity name={ri}/>}
            </>
        :
          ((r.rate.onCall || r.rate.onCallIn || r.rate.onDuty) && (
           ( !clinicClose && checktime(r)) && <Oncallactivity
              name={r.activityName}
              duration={r.duration}
              start={r.start}
              end = {r.end}
              color={r.color}
              type={r.activityType?r.activityType:"oc"}
              date={date}
              index={ri}
              
            /> ||  
            
            <Otheractivity
            name={r.activityName}
            duration={r.duration}
            start={r.start}
            end = {r.end}
            color={r.color}
            type={r.activityType?r.activityType:"oc"}
            date={date}
            index={ri}
            activity = {r}
          />
          )) ||
            (!r.rate.onCall && !r.rate.onCallIn && !r.rate.onDuty && (
              <>
             {/* <Box className="cell"> */}
             <Activity
                name={r.activityName}
                duration={r.duration}
                start={r.start}
                end = {r.end}
                color={r.color}
                type={r.activityType?r.activityType:"oc"}
                contd = {r.continue?r.continue:false}
              />
              {/* </Box> */}
               {/* <Box className="cell">
              {r.activityType=="aft" &&  <Activity
                 name={r.activityName}
                 duration={r.duration}
                 color={r.color}
                 type={r.activityType?r.activityType:"oc"}
               />}
               </Box> */}
               </>
            ))}
        </>
      ))

}
    </>
  );
};
// Main App component using the above components

export { Activity, Otheractivity, Leaveactivity, Activitycell,Oncallactivity };
