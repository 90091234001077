import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";

import { addYearAndMonth, checkcontractType, checkReportDisplay, get_option, handleDownload, loadOptions, reportcalculation } from "./functions";
import { useMaterialUIController } from "context";
import { generateWeekNumbersArray } from "layouts/scheduler/functions";
import { generateMonthsArray } from "layouts/scheduler/functions";
import moment from "moment";
import { calenderMode } from "layouts/scheduler/functions";
import { Chip, ClickAwayListener, Select } from "@mui/material";
import ReactCalendar from "react-calendar";
import { Leaveelement, Staffdata, Tableelement, Tableelementoncall } from "./component/component";
function Clinics() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    selecetdCustomer,
    selected_clinic,

    permission,
  } = controller;
  const weekList = generateWeekNumbersArray();
  const generateMonthList = generateMonthsArray();
  const [selectDay, setselectDay] = useState(new Date());
  const [mode, setMode] = useState(0);
  const [reportType, setreportType] = useState([]);
  const [activityList, setActivitylist] = useState([]);
  const [clinicList, setCliniclist] = useState([]);
  const [selcetedClinic,setSelectedClinic] = useState([])
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    startCal:false,
    endCal:false,
    key: "selection",
  });
  const [category,setcategory] = useState(["asv"])
  const [selectedWeek, setSelecetdWeek] = useState(moment().isoWeek()); // Set default value here

  const [userlist, setUserlist] = useState([]);
  const navigate = useNavigate();
  const { t ,i18n} = useTranslation();
  var language = i18n.language;
  const [name, setName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [cliniclist,setClinicList] = useState([])
  const [customerdata, setcustomerData] = useState([]);
  const [userlistbackup, setUserlistBackip] = useState([]);
  const [report,setReport] = useState([])
  const m = [
    "hour","days","collab"
  ]
  
  const loadData = async () => {
    let customer = selecetdCustomer.id;
    let clinicid = selected_clinic.id;
    if (
      customer !== "" &&
      customer !== undefined &&
      selected_clinic.id !== "" &&
      selected_clinic.id !== undefined
    ) {
      setLoading(true);
      try {
        var { staffdetails, clinicdetails, activities, contract, cliniclist } =
          await get_option(customer, clinicid);
        // setScheduleRecord(record);
        setActivitylist(activities);
        setCliniclist(cliniclist);
        var temp = []
        if(cliniclist.find((d)=>d.id==selected_clinic.id))
        {
          temp.push(cliniclist.find((d)=>d.id==selected_clinic.id))
        }
        
        setSelectedClinic(temp)
        // setDaterange(range);
        // setcustomerData(customerDetils);
        setUserlist(staffdetails);
        setUserlistBackip(staffdetails);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setUserlist([]);
        setActivitylist([]);
        setCliniclist([]);
        console.log("error ==>", error);
      }
    }
  };
  const handleSelectedClinic = (e) => {
    var temp = [...selcetedClinic];

    e.flag = true;
    if (temp.some((d) => d.id === e.id)) {
      temp = temp.filter((data) => data.id !== e.id);
      // setSelectedDates([...selectedDates, date]);
    } else {
      temp.push(e);
    }
    setSelectedClinic(temp);
  };
  const generateReport = async()=>{
    var clinic = selcetedClinic
    var selectedcategory = category
    
    // var selectedmode = mode
    var selectedmode = reportType[0]
    var startDate = selectionRange.startDate
    var endDate = selectionRange.endDate
    var customer = selecetdCustomer.id
    // if(clinic.length>0)
    // {
    //   alert(t("Please select some clinic to generate report"))
    //   return
    // }
setLoading(true)
    var response = await reportcalculation(customer,clinic,startDate,endDate,selectedcategory,selectedmode)
    if(response)
    {
      setReport(response)
    }else{
      setReport([])
    }
    setLoading(false)

  }
  const generateExcel = async()=>{
    var clinic = selcetedClinic
    var selectedcategory = category
    
    // var selectedmode = mode
    var selectedmode = reportType
    var startDate = selectionRange.startDate
    var endDate = selectionRange.endDate
    var customer = selecetdCustomer.id
    // if(clinic.length>0)
    // {
    //   alert(t("Please select some clinic to generate report"))
    //   return
    // }
setLoading(true)
    await handleDownload(customer,clinic,startDate,endDate,selectedcategory,selectedmode,t)
    setLoading(false)
// setReport(response)
  }
  useEffect(() => {
    if (selecetdCustomer.id && selected_clinic.id) {
      loadData();
      // loadOptions(selecetdCustomer.id,[selected_clinic.id])
    }
  }, [selecetdCustomer, selected_clinic, refresh]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container1 report-page">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                {t("Report")}
              </MDTypography>
            </MDBox>

            <Box>
              <Box
                className="report-heading"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box className="cs-dropdown icon ">
                  <Select
                            className="form-select"
                            id="languageSelector"
                            label="Country"
                            value={selcetedClinic}
                            required
                            onChange={(e) => {
                              handleSelectedClinic(e.target.value);
                            }}
                            renderValue={(clinicrecords) => (
                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {clinicrecords.map((clinic, index) => (
                                  <Chip key={index} label={clinic.label} />
                                ))}
                              </div>
                            )}
                          >
                            {clinicList.map((data, index) => (
                              <MenuItem value={data} select key={index}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </Select>
                  </Box>
                  <Box sx={{ display: "flex", ml: 4, mt: 1 }}>
                    <FormControlLabel
                      className="formlabel cs-checkbox"
                      label={t("Vétérinaire")}
                      name = "category"
                      value="vet"
                      control={
                        <Checkbox
                        checked={category.includes("vet")}
                          onChange={(e) =>
                         {
                          var temp = [...category]
                          if(temp.includes(e.target.value))
                          {
                            temp =   temp.filter(item => item !== e.target.value)
                          }else{
                            temp.push(e.target.value)
                          }
                          setcategory(temp)
                        // var temp = []
                        //  temp.push(e.target.value)
                        // setcategory(temp)
                         }
                       
                          }
                          />
                      }
                    
                    />
                    <FormControlLabel
                      className="formlabel cs-checkbox"
                      sx={{ ml: 2 }}
                      label={t("ASV")}
                      name = "category"
                      value="asv"
                      control={<Checkbox
                        checked={category.includes("asv")}
                        onChange={(e) =>
                          {
                            var temp = [...category]
                            if(temp.includes(e.target.value))
                            {
                              temp =   temp.filter(item => item !== e.target.value)
                            }else{
                              temp.push(e.target.value)
                            }
                            setcategory(temp)
                          }
                        }
                        />
                        
                      
                      }
                     
                    />
                     <FormControlLabel
                      className="formlabel cs-checkbox"
                      sx={{ ml: 2 }}
                      label={t("Other")}
                      name = "category"
                      value="oth"
                      control={<Checkbox
                        checked={category.includes("oth")}
                        onChange={(e) =>
                          {
                       
                            var temp = [...category]
                            if(temp.includes(e.target.value))
                            {
                              temp =   temp.filter(item => item !== e.target.value)
                            }else{
                              temp.push(e.target.value)
                            }
                            setcategory(temp)
                          }
                        }
                        />
                        
                      
                      }
                     
                    />
                  </Box>
                </Box>
                <MDButton
                  className="primary-btn btn"
                  // onClick={() => add_leave_schedule()}
                  onClick={()=>generateExcel()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="15"
                    viewBox="0 0 19 15"
                    fill="none"
                  >
                    <path
                      d="M7.67944 0.564453H2.8246C2.45672 0.564453 2.1039 0.710593 1.84377 0.970724C1.58364 1.23086 1.4375 1.58367 1.4375 1.95155V13.0483C1.4375 13.4162 1.58364 13.769 1.84377 14.0291C2.1039 14.2893 2.45672 14.4354 2.8246 14.4354H11.1472C11.5151 14.4354 11.8679 14.2893 12.128 14.0291C12.3881 13.769 12.5343 13.4162 12.5343 13.0483V5.41929L7.67944 0.564453Z"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.67969 0.564453V5.41929H12.5345"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.85254 9.23389L17.5622 9.23389"
                      stroke="#16273C"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.85254 9.23389L17.5622 9.23389"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.7881 6.45972L17.5623 9.23391L14.7881 12.0081"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  &nbsp;&nbsp;{t("Export to Excel")}
                </MDButton>
              </Box>
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              >
                <Box className="report-section">
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <FormGroup className="input-icon"
                     onClick={() =>
                      setSelectionRange((prev) => ({
                        ...prev,
                        startCal: true,
                      }))
                    }
                    >
                      <InputLabel className="formlabel">
                        {t("Start date")}
                      </InputLabel>
                      <TextField
                        className="forminput"
                        placeholder={t("Start date")}
                        variant="outlined"
                        value={moment(selectionRange.startDate).format(
                          "DD-MM-YYYY"
                        )}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <MDButton>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            d="M13.6664 2.62036H2.33464C1.44059 2.62036 0.71582 3.27854 0.71582 4.09045V14.381C0.71582 15.1929 1.44059 15.8511 2.33464 15.8511H13.6664C14.5605 15.8511 15.2852 15.1929 15.2852 14.381V4.09045C15.2852 3.27854 14.5605 2.62036 13.6664 2.62036Z"
                            fill="#16273C"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.33412 3.26911C1.83511 3.26911 1.43059 3.63647 1.43059 4.08963V14.3802C1.43059 14.8334 1.83511 15.2007 2.33412 15.2007H13.6659C14.1649 15.2007 14.5694 14.8334 14.5694 14.3802V4.08963C14.5694 3.63647 14.1649 3.26911 13.6659 3.26911H2.33412ZM0 4.08963C0 2.91897 1.04502 1.96997 2.33412 1.96997H13.6659C14.955 1.96997 16 2.91897 16 4.08963V14.3802C16 15.5509 14.955 16.4999 13.6659 16.4999H2.33412C1.04502 16.4999 0 15.5509 0 14.3802V4.08963Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.2397 0.500244C11.6348 0.500244 11.955 0.791067 11.955 1.14982V4.08998C11.955 4.44873 11.6348 4.73956 11.2397 4.73956C10.8447 4.73956 10.5244 4.44873 10.5244 4.08998V1.14982C10.5244 0.791067 10.8447 0.500244 11.2397 0.500244Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.76217 0.500244C5.15722 0.500244 5.47746 0.791067 5.47746 1.14982V4.08998C5.47746 4.44873 5.15722 4.73956 4.76217 4.73956C4.36712 4.73956 4.04688 4.44873 4.04688 4.08998V1.14982C4.04688 0.791067 4.36712 0.500244 4.76217 0.500244Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 7.0297C0 6.67095 0.320248 6.38013 0.715294 6.38013H15.2847C15.6798 6.38013 16 6.67095 16 7.0297C16 7.38845 15.6798 7.67927 15.2847 7.67927H0.715294C0.320248 7.67927 0 7.38845 0 7.0297Z"
                            fill="white"
                          />
                        </svg>
                      </MDButton>
                      {selectionRange.startCal && (
                              <ClickAwayListener
                                onClickAway={() =>
                                  setSelectionRange((prev) => ({
                                    ...prev,
                                    startCal: false,
                                  }))
                                }
                              >
                                <div>
                                  <ReactCalendar
                                    // onChange={(e) => console.log(e)}
                                    value={selectionRange.startDate}
                                    selectRange={false}
                                    // minDate={new Date()}
                                    locale={language == "fr" ? "fr-FR" :"en-EN"}
                                    onChange={(e) =>
                                      setSelectionRange((prev) => ({
                                        ...prev,
                                        startDate: e,
                                      }))
                                    }
                                  />
                                </div>
                              </ClickAwayListener>
                            )}
                    </FormGroup>
                    <FormGroup
                      className="input-icon"
                      sx={{
                        ml: 2,
                      }}
                      onClick={() =>
                        setSelectionRange((prev) => ({
                          ...prev,
                          endCal: true,
                        }))
                      }
                    >
                      <InputLabel className="formlabel">
                        {t("End date")}
                      </InputLabel>
                      <TextField
                        className="forminput"
                        // placeholder={t("23 JAN - 30 JAN, 2024")}
                        variant="outlined"
                        value={moment(selectionRange.endDate).format(
                          "DD-MM-YYYY"
                        )}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <MDButton>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            d="M13.6664 2.62036H2.33464C1.44059 2.62036 0.71582 3.27854 0.71582 4.09045V14.381C0.71582 15.1929 1.44059 15.8511 2.33464 15.8511H13.6664C14.5605 15.8511 15.2852 15.1929 15.2852 14.381V4.09045C15.2852 3.27854 14.5605 2.62036 13.6664 2.62036Z"
                            fill="#16273C"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.33412 3.26911C1.83511 3.26911 1.43059 3.63647 1.43059 4.08963V14.3802C1.43059 14.8334 1.83511 15.2007 2.33412 15.2007H13.6659C14.1649 15.2007 14.5694 14.8334 14.5694 14.3802V4.08963C14.5694 3.63647 14.1649 3.26911 13.6659 3.26911H2.33412ZM0 4.08963C0 2.91897 1.04502 1.96997 2.33412 1.96997H13.6659C14.955 1.96997 16 2.91897 16 4.08963V14.3802C16 15.5509 14.955 16.4999 13.6659 16.4999H2.33412C1.04502 16.4999 0 15.5509 0 14.3802V4.08963Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.2397 0.500244C11.6348 0.500244 11.955 0.791067 11.955 1.14982V4.08998C11.955 4.44873 11.6348 4.73956 11.2397 4.73956C10.8447 4.73956 10.5244 4.44873 10.5244 4.08998V1.14982C10.5244 0.791067 10.8447 0.500244 11.2397 0.500244Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.76217 0.500244C5.15722 0.500244 5.47746 0.791067 5.47746 1.14982V4.08998C5.47746 4.44873 5.15722 4.73956 4.76217 4.73956C4.36712 4.73956 4.04688 4.44873 4.04688 4.08998V1.14982C4.04688 0.791067 4.36712 0.500244 4.76217 0.500244Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 7.0297C0 6.67095 0.320248 6.38013 0.715294 6.38013H15.2847C15.6798 6.38013 16 6.67095 16 7.0297C16 7.38845 15.6798 7.67927 15.2847 7.67927H0.715294C0.320248 7.67927 0 7.38845 0 7.0297Z"
                            fill="white"
                          />
                        </svg>
                      </MDButton>
                      {selectionRange.endCal && (
                                <ClickAwayListener
                                  onClickAway={() =>
                                    setSelectionRange((prev) => ({
                                      ...prev,
                                      endCal: false,
                                    }))
                                  }
                                >
                                  <div>
                                    <ReactCalendar
                                      // onChange={(e) => console.log(e)}
                                      value={selectionRange.endDate}
                                      selectRange={false}
                                      // minDate={new Date()}/
                                      minDate={selectionRange.startDate}
                                      maxDate={addYearAndMonth(selectionRange.startDate)}
                                      locale={language == "fr" ? "fr-FR" :"en-EN"}
                                      onChange={(e) =>
                                        setSelectionRange((prev) => ({
                                          ...prev,
                                          endDate: e,
                                        }))
                                      }
                                    />
                                  </div>
                                </ClickAwayListener>
                              )}
                    </FormGroup>
                  </Box>
                  
                 
                  <Box className="navigation-link">
                <MDButton
                  className="primary-btn btn xl"
                  onClick={() =>{
                    generateReport()
                  }}
                >
                
                  &nbsp;&nbsp;{t("Generate Report")}
                </MDButton>
              </Box>
                </Box>
                <Box 
                sx={{ display: "flex", mt: 1, width:"50%" }}>
                    <FormControlLabel
                      className="formlabel cs-checkbox"
                      label={t("Hours contract")}
                      name = "Hours contract"
                    value={0}
                      control={
                        <Checkbox
                        checked={reportType.includes(0)}
                        onChange={(e) =>
                          {
                            var temp = [...reportType]
                            if(temp.includes(Number(e.target.value)))
                            {
                              temp =   temp.filter(item => item != Number(e.target.value))
                            }else{
                              temp.push(Number(e.target.value))
                            }
                            setreportType(temp)
                          }
                        }
                          />
                      }
                    
                    />
                    <FormControlLabel
                      className="formlabel cs-checkbox"
                      sx={{ ml: 2 }}
                      label={t("Days of Contract")}
                      name = "Days of Contract"
                      value={1}
                      control={<Checkbox
                        checked={reportType.includes(1)}
                        onChange={(e) =>
                          {
                            var temp = [...reportType]
                            if(temp.includes(Number(e.target.value)))
                            {
                              temp =   temp.filter(item => item != e.target.value)
                            }else{
                              temp.push(Number(e.target.value))
                            }
                            setreportType(temp)
                          }
                        }
                        />
                        
                      
                      }
                     
                    />
                     <FormControlLabel
                      className="formlabel cs-checkbox"
                      // sx={{ ml: 2 }}
                      label={t("Collabs")}
                      name = "Collabs"
                      value={2}
                      control={<Checkbox
                        checked={reportType.includes(2)}
                        onChange={(e) =>
                          {
                            var temp = [...reportType]
                            if(temp.includes(Number(e.target.value)))
                            {
                              temp =   temp.filter(item => item != Number(e.target.value))
                            }else{
                              temp.push(Number(e.target.value))
                            }
                            console.log("selected report type == ",reportType)
                            setreportType(temp)
                          }
                        }
                        />
                        
                      
                      }
                     
                    />
                  </Box>
                <Box>
                    {/* <Box className="tab-main">
                      <Tabs value={mode} onChange={(e, newValue) => setMode(newValue)}>
                        <Tab label={t("Hours contract")} value={0}  className={ mode==0 && "active"}  />
                        <Tab label={t("Days of Contract")} value={1}  className={ mode==1 && "active"}  />
                        <Tab label={t("Collabs")} value={2}  className={ mode==2 && "active"}/>
                      </Tabs>
                    </Box> */}
                  </Box>
                {reportType.map((mode,index)=>(<Box className="report-table">
                 
                 {Object.keys(report).length >0 && <Box>
                    <Box className="group-main ">
                    <Box className="user-group ">
                      <Box className="user-data">
                        {/* Clinic 1 start*/}
                      
                     { Object.entries(report).map(([key, value]) => (
                      
                      <Box>
                    
                     { checkReportDisplay(value) && <>
                     <Box className="blank-heading">
                     <ul>
                        <li>
                        {mode==0?t("Hours contract"):mode==1?t("Days of Contract"):t("Collabs")}
                        </li>
                        </ul>
                     </Box>
                       <Box className="blank"></Box>
                        <ul>
                          {Object.entries(value.report).map(([k, d]) => (
                            checkcontractType(d.userdetails.contractType) == mode && <li>{d.username}</li>
                          ))}
                        </ul></>}
                       </Box>
                      
                      
                      ))}
     
                      </Box>
                      </Box>
                      
                      <Box className="activity-grid">

                        <Box className="grid-box">
                          <Box className="top-month-bar">
                            <h5>{t("Activity")}</h5>
                          </Box>
                        {  Object.entries(report).map(([key, value]) => (
                          <>
                          { checkReportDisplay(value) && 
                          <>
                          <Box className="mid-bar">
                          <h6>{value.clinicdetails.label}</h6>
                          </Box>
                          <Box className="calender-list">
                            <ul>
                            {value.activitylist.map((d,i)=>(
                            <li>{d.activityName}</li>
                          ))}
                          <li>{t("Total hours")}</li>
                          <li>{t("Total days")}</li>
                            </ul>
                          </Box>

                         
                          <Box>
                          {  Object.entries(value.report).map(([k, d]) => (
                        checkcontractType(d.userdetails.contractType) == mode &&     <Tableelement user = {d.mapid} activity={value.activitylist} report={value} mode = {mode}/>
                          ))}
                         

                          </Box>

                          </>
                        }</>))}
                         
                        </Box>

                        <Box  className="grid-box">
                          <Box className="top-month-bar">
                            <h5>{t("oncall activity")}</h5>
                          </Box>
                          {  Object.entries(report).map(([key, value]) => ( 
                            
                            <>
                          { checkReportDisplay(value) && 
                            <>
                          <Box className="mid-bar">
                            <h6>{value.clinicdetails.label}</h6>
                          </Box>
                          <Box className="calender-list">
                            <ul>
                            {value.oncallList.map((d,i)=>(
                            <li>{d.activityName}</li>
                          ))}
                           <li>{mode==0?t("Total hours"):t("Total days")}</li>
                           <li>{t("Total quantity")}</li>
                            </ul>
                          </Box>

                         
                          <Box>
                          {Object.entries(value.report).map(([k, d]) => (
                           checkcontractType(d.userdetails.contractType) == mode &&  <Tableelementoncall user = {d.mapid} activity={value.oncallList} report={value} mode = {mode}/>
                          ))}
                         

                          </Box>

                          </>}</>))}
                        
                        </Box>
                          
                          
                        <Box  className="grid-box">
                          <Box className="top-month-bar">
                            <h5>{t("Leave")}</h5>
                          </Box>
                          {  Object.entries(report).map(([key, value]) => ( 
                            
                            <>
                          { checkReportDisplay(value) && 
                            <>
                          <Box className="mid-bar">
                            <h6></h6>
                          </Box>
                          <Box className="calender-list">
                            <ul>
                           
                            <li>{t("No of days")}</li>
                       
                          
                            </ul>
                          </Box>

                         
                          <Box>
                          {Object.entries(value.report).map(([k, d]) => (
                            checkcontractType(d.userdetails.contractType) == mode && <Leaveelement user = {d.mapid}  report={value} mode = {mode}/>
                          ))}
                         

                          </Box>

                          </>}</>))}
                        
                        </Box>

                        <Box  className="grid-box">
                          <Box className="top-month-bar">
                            <h5>{t("Personnal Data")}</h5>
                          </Box>
                          {  Object.entries(report).map(([key, value]) => ( 
                            
                            <>
                          { checkReportDisplay(value) && 
                            <>
                          <Box className="mid-bar">
                          <h6>{value.clinicdetails.label}</h6>
                          </Box>
                          <Box className="calender-list">
                            <ul>
                           
                            <li>{t("Contract Start")}</li>
                            <li>{t("Contract End")}</li>
                            <li>{t("Type of contract")}</li>
                            {/* <li>{t("No of days in contract")}</li> */}
                          
                            </ul>
                          </Box>

                         
                          <Box>
                          {Object.entries(value.report).map(([k, d]) => (
                           checkcontractType(d.userdetails.contractType) == mode &&  <Staffdata user = {d.mapid}  report={value} mode = {mode}/>
                          ))}
                         

                          </Box>

                          </>}</>))}
                        
                        </Box>

                      </Box>
                    </Box>
                  </Box>}
                </Box>))}
              </MDBox>
             
            </Box>
          </Box>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default Clinics;
