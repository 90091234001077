import { db } from "backendservice/firebase";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,compound,
  or,

  and,
  deleteDoc,
  increment,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import {
  activities,
  clinic,
  contract,
  countryList,
  customers,
  deviceCatelogue,
  employeeType, leave,
  loginsessionref,
  module,
  plan,
  plans,
  schedule,
  serviceList,
  staff,
  storage,
  terms,
  tickets,
  user,
  userroles,
} from "./firebase";
import { getInitials } from "layouts/staff/functions";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
const calculate_Time_Passed = (oldtime,currenttime) => {

  const startDate = oldtime;
  const endDate = currenttime;
//     const startDate = new Date('2024-07-06T10:00:00');
// const endDate = new Date('2024-07-07T15:30:45');

// Calculate the difference in milliseconds
const diffMs = endDate - startDate;

// Convert milliseconds to days, hours, minutes, and seconds
const msPerSecond = 1000;
const msPerMinute = msPerSecond * 60;
const msPerHour = msPerMinute * 60;
const msPerDay = msPerHour * 24;

const days = Math.floor(diffMs / msPerDay);
const hours = Math.floor((diffMs % msPerDay) / msPerHour);
const minutes = Math.floor((diffMs % msPerHour) / msPerMinute);
const seconds = Math.floor((diffMs % msPerMinute) / msPerSecond);
  // Calculate the difference in seconds
  // console.log(`Difference: ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`);
  return seconds
}
const createSupportTicket = async (data) => {
  var id = uuidv4();
  data.id = id;
  data.ticketNumber = generateUniqueTicketNumber();
  data.raiseTime = new Date();
  data.requestInfo = await getCountryName();

  try {
    var docref = await setDoc(doc(db, "tickets", data.id), data);
  } catch (error) {
    console.log(error);
  }
};




async function getCountryName() {
  const response = await fetch(process.env.REACT_APP_IP_API);
  const resData = await response.json();

  return resData;
}
function generateUniqueTicketNumber() {
  const prefix = "PHY";
  const timestamp = Date.now(); // Use current timestamp as a unique identifier
  const randomSuffix = Math.floor(Math.random() * 1000); // Add a random number for additional uniqueness

  const ticketNumber = `${prefix}${timestamp}-${randomSuffix}`;
  return ticketNumber;
}
const deleteLeaveRecordById = async (id) => {
  try {
    const leaveDocRef = doc(db, 'leave', id);
    await deleteDoc(leaveDocRef);
    console.log(`Document with ID ${id} deleted successfully.`);
  } catch (error) {
    console.error("Error deleting document: ", error);
  }
};
const get_countryList = async () => {
  try {
    var country = await getDocs(countryList);
    // console.log(contrydetails[0].data)
    const contrydetails = country.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    console.log("countr ==",contrydetails[0].data)
    var list = [];
    var pos = 0;
    Object.entries(contrydetails[0].data).forEach(([country, details]) => {
      list.push({
        label: details.Country,
        data: details,
        checked: false,
        subRegion: details["sub-region"],
      });
    });
    var sortList = list
      .slice() // Create a shallow copy of the array to avoid modifying the original
      .sort((a, b) => a.label.localeCompare(b.label));
    // console.log("list ===",bewlist)
    sortList.map((d, i) => {
      d.pos = pos++;
    });
    return sortList;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const get_customer = async (customerid) => {
  try {
    var roleref = await doc(db, process.env.REACT_APP_CUSTOMERS, customerid);
    var snapshot = await getDoc(roleref);
    var response = snapshot.data();
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const updateCustomer = async (customerid, data) => {
  try {
    await updateDoc(doc(db, process.env.REACT_APP_CUSTOMERS, customerid), data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const get_all_customer = async () => {
  try {
    var snapshot = await getDocs(customers);
    const response = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      label: doc.data().company,
    }));
    //console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const addCustomer = async (data) => {
  await addDoc(customers, data);
};

const get_user_details = async (uid) => {
  try {
    var query = doc(db, process.env.REACT_APP_USER, uid);
    var userdetails = await getDoc(query);
    var userdata = userdetails.data();
    // create_user(userdata)
    return userdata;
  } catch (error) {
    console.log("error ",error)
    return false;
  }
};
const create_user = async (data) => {
  try {
    data.id = "DGGU8kj8WnXv18LIX7a3MtYEbSc2";
    // var docref = await setDoc(doc(db, "user", data.id), data);
    return true;
  } catch (error) {
    return false;
  }
};
const create_login_session = async (logindata) => {
  try {
    const loginsession = await addDoc(loginsessionref, logindata);
    return true;
  } catch (error) {
    return false;
  }
};
const get_user_terms = async (uid) => {
  try {
    var snapshot = await getDocs(query(terms, where("uid", "==", uid)));

    var response = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    // var response = snapshot.data();
    //console.log("response", response);
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const write_terms = async (data, id) => {
  try {
    var resp = await get_user_terms(data.uid);
    if (resp.length > 0) {
      await updateDoc(doc(db, "termsConditions", id), data);
    } else {
      await addDoc(terms, data);
    }

    // var response = snapshot.data();
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const get_clinic = async (customer) => {
  //console.log("customer ", customer);
  try {
    var snapshot = await getDocs(
      query(clinic, where("customer", "==", customer))
    );
    // console.log("data =====>",snapshot)
    var storedatas = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      label: doc.data().storeName,
      region:doc.data().operatedRegion.region,
      active: doc.data().active,
      createdTime: doc.data().createdTime,
    }));
    //console.log(storedatas);
    return storedatas;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const get_clinic_id = async (clinicid) => {

  try {
    var clinicref = await doc(db, process.env.REACT_APP_CLINIC, clinicid);
    var snapshot = await getDoc(clinicref);
    var clinic = snapshot.data();
 
    return clinic;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const update_clinic = async (clinicid,data) => {

  try {
    var snapshot = await updateDoc(doc(db, process.env.REACT_APP_CLINIC, clinicid),data);
    // console.log("data =====>",snapshot)

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const updateStatus = async (status, id) => {
  try {
    var snapshot = await updateDoc(doc(db, process.env.REACT_APP_CLINIC, id), { active: status });
    // console.log("data =====>",snapshot)

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const add_clinic = async (data) => {
  try {
    var id = uuidv4();
    data.id = id;

    const docref = await setDoc(doc(db, process.env.REACT_APP_CLINIC, id), data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const get_contract = async (customer,selected_clinic,active) => {
  // console.log("customer ",customer)
  try {
    let queryContract = query(contract, where("customer", "==", customer));

    if (selected_clinic) {
      if(active)
        {
          queryContract = query(contract, where("customer", "==", customer), where("defaultClinicId", "==", selected_clinic), where("active", "==", true));
          
        }else{
          queryContract = query(contract, where("customer", "==", customer), where("defaultClinicId", "==", selected_clinic));

        }
    }

    const snapshot = await getDocs(queryContract);
    // console.log("data =====>",snapshot)
    var response = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      label: doc.data().contract,
      createdTime: doc.data().createdTime,
      ...doc.data(),
    }));
    //console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const get_employeeType = async (customer,selected_clinic) => {
  // console.log("customer ",customer)
  try {
    let employeeContract = query(employeeType, where("customer", "==", customer));

    if (selected_clinic) {
      employeeContract = query(employeeType, where("customer", "==", customer), where("defaultClinicId", "==", selected_clinic), where("active", "==", true));
    }
    const snapshot = await getDocs(employeeContract);
    
    // console.log("data =====>",snapshot)
    var response = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      label: doc.data().employeeType,
      ...doc.data(),
    }));
    //console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const updateContract_status = async (status, id) => {
  try {
    var snapshot = await updateDoc(doc(db, "contract", id), { active: status });
    // console.log("data =====>",snapshot)

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const edit_contract = async (data, id) => {
  try {
    var snapshot = await updateDoc(doc(db, "contract", id), data);
    // console.log("data =====>",snapshot)

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const add_contract = async (data) => {
  try {
    var id = uuidv4();
    data.id = id;

    const docref = await setDoc(doc(db, "contract", id), data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const add_staff = async (data) => {
  try {
    var id = uuidv4();
    data.id = id;
    data.createdTime = new Date();
    //console.log(data, " from firebase");
    const docref = await setDoc(doc(db, "user", id), data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const get_role = async (roleid) => {
  // console.log("customer ",customer)
  try {
    console.log("role id == ",roleid)
    var snapshot = await getDoc(
      doc(db, process.env.REACT_APP_USERROLES, roleid)
    );
    // console.log("data =====>",snapshot)

    //console.log(snapshot.data());
    return snapshot.data();
  } catch (error) {
    console.log(error);
    return {};
  }
};
const get_roles = async (customer,active) => {
  // console.log("customer ",customer)
  try {
 
      let roleQuery = query(userroles, where("customer", "==", customer));

      if (active) {
        roleQuery = query(userroles, where("customer", "==", customer), where("active", "==", true));
      }
      var snapshot = await getDocs(roleQuery);
      
  
    // console.log("data =====>",snapshot)
    var response = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      label: doc.data().role,
      createdTime: doc.data().createdTime,
      ...doc.data(),
    }));
    var snapshot = await getDocs(
      query(userroles, where("global", "==", true))
    );
    var response2 = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      label: doc.data().role,
      createdTime: doc.data().createdTime,
      ...doc.data(),
    }));
    var temp =response.concat(...response2)
    //console.log(temp);
    return temp;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const get_rolebased_users = async (role, customer) => {
  // console.log("customer ",customer)
  try {
    var snapshot = await getDocs(
      query(user, where("customer", "==", customer), where("group", "==", role))
    );
    // console.log("data =====>",snapshot)
    var response = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      label: doc.data().name,
      createdTime: doc.data().createdTime,
      ...doc.data(),
    }));
    //console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const updateRoleStatus = async (status, id) => {
  try {
    var snapshot = await updateDoc(doc(db, "userroles", id), {
      active: status,
    });
    // console.log("data =====>",snapshot)

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const edit_roles = async (data, id) => {
  try {
    var snapshot = await updateDoc(doc(db, "contract", id), data);
    // console.log("data =====>",snapshot)

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const add_roles = async (data) => {
  try {
    var id = uuidv4();
    data.id = id;
    data.group = id;
    data.createdTime = new Date();
    const response = await setDoc(doc(db, "userroles", id), data);
    // const docref = await setDoc(doc(db, "modules", data.id), data);
    return id;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const update_role = async (id, data) => {
  try {
    data.createdTime = new Date();
    const response = await updateDoc(doc(db, "userroles", id), data);
    // const docref = await setDoc(doc(db, "modules", data.id), data);
    return id;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const get_module = async () => {
  // console.log("customer ",customer)
  try {
    var snapshot = await getDocs(query(module, where("type", "==", "pages")));
    // console.log("data =====>",snapshot)
    var response = snapshot.docs.map((doc) => ({
      ...doc.data(),
    }));
    // console.log("module response ==",response)
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const get_staff = async (customer) => {
  //console.log("customer from firebasefun", customer);
  try {
    var snapshot = await getDocs(
      query(user, where("customer", "==", customer))
    );
    // console.log("data =====>",snapshot)
    var staffdata = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      label: doc.data().name,

      ...doc.data(),
    }));
    // console.log(storedatas)
    return staffdata;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const get_staff_clinic = async (customer,clinic) => {
  //console.log("customer from firebasefun", customer,clinic);
  try {
    var snapshot = await getDocs(
      query(user,and(  
         where("customer", "==", customer),
      // Second condition: "clinic" field is in a list of specific values
     or(
        // Condition 1: "clinic" field is in a list of specific values
        where("clinic", "array-contains", clinic),
        // Condition 2: "defaultclinic" field matches a specific value
        where("defaultClinicId", "==", clinic)
      ))
    )
    );
    // defaultClinic ,clinic
    // console.log("data =====>",snapshot)
    var staffdata = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      value: doc.id,
      label: doc.data().name + " " + doc.data().surname,
      initials: getInitials(doc.data().name, doc.data().surname),
     ...doc.data(),
    }));
 
    const sorted = staffdata.sort((a, b) => {
      const firstCharA = a.label.trim().charAt(0).toLowerCase();
    const firstCharB = b.label.trim().charAt(0).toLowerCase();

      if (firstCharA < firstCharB) return -1;
      if (firstCharA > firstCharB) return 1;
      return 0;
    })
    // console.log(storedatas)
    return sorted;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const get_user = async (emailId) => {
  try {
    var snapshot = await getDocs(
      query(user, where("email", "==", emailId))
    );
    // console.log("data =====>",snapshot)
    var foundUser = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      data: doc.data(),
    }));
    return foundUser;
  } catch (error) {
    console.log(error);
    return "";
  }
};
const get_users = async (customer) => {
  //console.log("customer from firebasefun", customer);
  try {
    var snapshot = await getDocs(
      query(user, where("customer", "==", customer))
    );
    // console.log("data =====>",snapshot)
    var staffdata = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      checked: false,
      value: doc.id,
      label: doc.data().name + " " + doc.data().surname,
      initials: getInitials(doc.data().name, doc.data().surname),
      group: doc.data().group,
      profile: doc.data().profile == undefined ? "" : doc.data().profile,
      ...doc.data(),
    }));
    //console.log(staffdata);
      return staffdata;
      }
      
     

      

      
   catch (error) {
    console.log(error);
    return [];
  }}

const update_leave_status = async (id,status) => {
  try {
    //console.log({id,status},"from helper");
      const docref = await updateDoc(
          doc(db,"leave",id ),
          {status:status}
        )
  } catch (error) {
      console.log(error);
  }
}
const add_leave = async(data)=>{
  try{
    var id = uuidv4();
    data.id = id;
    data.createdTime = new Date()
    const docref = await setDoc(doc(db, "leave", id), data);
    return true
  }catch(error)
  {
    console.log(error)
    return false
  }
  
}
const get_notification_leave = async (customer, selected_clinic) => {
  try {
    let queryLeave = query(leave, where("customer", "==", customer));

    if (selected_clinic) {
      queryLeave = query(leave, where("customer", "==", customer), where("defaultClinicId", "==", selected_clinic) , where("status", "==" , null));
    }

    const snapshot = await getDocs(queryLeave);

    const leaveData = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      label: doc.data().staff,
      ...doc.data()
    }));

    return leaveData;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const get_help_notification = async (customer,selected_clinic) => {
  try {
    let helpQuery = query(tickets, where("customer", "==", customer), where("defaultClinicId", "==", selected_clinic));

    const snapshot = await getDocs(helpQuery);

    const helpData = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data()
    })).filter((item) => item.data.statusCode === 0 || item.data.statusCode === 1);

    return helpData;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const get_admin_leave = async (customer, selected_clinic) => {
  try {
    let queryLeave = query(leave, where("customer", "==", customer));

    if (selected_clinic) {
      queryLeave = query(leave, where("customer", "==", customer), where("defaultClinicId", "==", selected_clinic));
    }

    const snapshot = await getDocs(queryLeave);

    const leaveData = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
      label: doc.data().staff,
      ...doc.data()
    }));

    return leaveData;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const get_leave_details = async (id) => {
  try {
    var roleref = await doc(db,"leave", id);
    var snapshot = await getDoc(roleref);
    const leavedata = snapshot.data()
    // const leaveData = snapshot.docs.map((doc) => ({
    //   id: doc.id,
    //   data: doc.data(),
    //   label: doc.data().staff,
    //   ...doc.data()
    // }));

    return leavedata;
  } catch (error) {
    console.log(error);
    return [];
  }
};

    const get_staff_leave = async (userId, selected_clinic) => {
      try {
        let queryLeave = query(leave, where("userId", "==", userId));
    
        if (selected_clinic) {
          queryLeave = query(leave, where("userId", "==", userId), where("defaultClinicId", "==", selected_clinic));
        }
    
        const snapshot = await getDocs(queryLeave);
    
        const leaveData = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
          label: doc.data().staff,
          ...doc.data()
        }));
    
        return leaveData;
      } catch (error) {
        console.log(error);
        return [];
      }
    };
    const get_staff_leave_pending = async (userId, selected_clinic) => {
      try {
     
          var queryLeave = query(leave, where("userId", "==", userId), where("defaultClinicId", "==", selected_clinic),where("status", "==", null));
        // console.log("userid == ",userId,"clinic id",selected_clinic)
    
        const snapshot = await getDocs(queryLeave);
    
        const leaveData = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
          label: doc.data().staff,
          ...doc.data()
        }));
    
        return leaveData;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

        const update_leave = async (id, temp) => {
          temp.updatedAt = new Date()
          const docref = await updateDoc(doc(db, "leave", id), temp);
        };

const update_staff = async (id, temp) => {
  const docref = await updateDoc(doc(db, "user", id), temp);
};



const update_User = async (data, id) => {
  try {
    //console.log("addresp id to upda",id,data)
    const update = await updateDoc(doc(db, "user", id), {
      group: data.id,
      description: data.name,
    });
    return true;
  } catch (error) {
    console.log("addresp",error);
    return false;
  }
};
const get_activities = async (id,clinic=null) => {
  try {
    var snapshot  = null
    if(clinic==null)
    {
       snapshot = await getDocs(
        query(activities, where("customer", "==", id))
      );
    }else{
       snapshot = await getDocs(
        query(activities,and(  where("customer", "==", id), or(
          // Condition 1: "clinic" field is in a list of specific values
          where("selectedClinic", "array-contains", clinic),
          // Condition 2: "defaultclinic" field matches a specific value
          where("defaultClinicId", "==", clinic)
        )))
      );
    }
  
    var activitiesData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data().data,
    }));
    // console.log("before sort ",activitiesData)
    const sortedActivities = activitiesData.sort((a, b) => {
      const firstCharA = a.activityName.trim().charAt(0).toLowerCase();
    const firstCharB = b.activityName.trim().charAt(0).toLowerCase();

      if (firstCharA < firstCharB) return -1;
      if (firstCharA > firstCharB) return 1;
      return 0;
  });
  // console.log("after  sort ",sortedActivities)
    return sortedActivities;
  } catch (error) {
    console.log(error);
    return false;
  }
};
const CounterUpdate = async (coll,inc,id)=>{
try{  
  console.log("increment id",id)
  const docRef =doc(db, coll, id);
  await updateDoc(docRef, {
    attachCounter:increment(inc) // Increment the counter by 1
});
return true
}catch(error)
{
  console.log(error)
  return false
}

}
const deleteDocument = async (coll,id) => {
  try {
 
    
    const docRef =doc(db, coll, id);
    const rec = await getDoc(docRef);
    if (rec.exists) {
        const data = rec.data();
        if (data.attachCounter === 0 || data.attachCounter === undefined) {
            await deleteDoc(docRef);
            console.log('Document successfully deleted!');
            return true
        } else {
            console.log('Counter is not 0, document not deleted.');
            return false
        }
    } else {
        console.log('No such document!');
        return false
    }
  } catch (error) {
      console.error('Error removing document: ', error);
      return false
  }
};
const  add_shedule = async(data)=>{
  try {
    var id = uuidv4();
    data.id = id;
    data.createdTime = new Date();
    //console.log(data, " from firebase");
    const docref = await setDoc(doc(db, "schedule", id), data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
function getMonthsBetweenDates(start, end) {
  const months = [];

  const currentDate = moment(start, "YYYY-MM-DD");
  const endDate = moment(end, "YYYY-MM-DD");

  if (!currentDate.isValid() || !endDate.isValid()) {
    throw new Error("Invalid date format. Dates must be in YYYY-MM-DD format.");
  }

  if (currentDate > endDate) {
    throw new Error("Start date must be before end date.");
  }

  while (currentDate <= endDate) {
    const yearMonth = currentDate.format("YYYY-MM");
    if (!months.includes(yearMonth)) {
      months.push(yearMonth);
    }
    currentDate.add(1, 'month').startOf('month');
  }

  return months;
}
function splitScheduleByMonths(schedule) {
  const monthlySchedules = {};

  Object.keys(schedule).forEach(date => {
    // Extract the year and month from the date string
    const [year, month] = date.split('-');

    // Create a key for the year-month
    const yearMonth = `${year}-${month}`;

    // Initialize the year-month key if it doesn't exist
    if (!monthlySchedules[yearMonth]) {
      monthlySchedules[yearMonth] = { };
    }

    // Add the record to the corresponding year-month
    monthlySchedules[yearMonth][date] = schedule[date];
  });

  return monthlySchedules;
}
const  get_shedule = async(brandId, clinicId, userId,start,end)=>{
  
  try {
    var schedule = {}
    const months = getMonthsBetweenDates(start,end)
    // console.log("months ",months,start,end)
    for (const month of months) {
      const [year, monthNumber] = month.split("-");
      const userRef = doc(db, `schedule/${brandId}/${clinicId}/${userId}/${year}/${monthNumber}`);
      // const userRef = doc(db, `schedule/${brandId}/${clinicId}/${userId}`);
  // console.log(`schedule/${brandId}/${clinicId}/${userId}/${year}/${monthNumber}`)
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          const scheduleData = userData.schedule; // Accessing schedule data
          // Do something with scheduleData
         if( Object.keys(scheduleData).length>0)
         {Object.assign(schedule,scheduleData)}
          // return scheduleData
      } }
      // console.log("new fetch",schedule)
      return schedule
  } catch (error) {
      console.error("Error fetching user data:", error);
      return {}
  }
}
async function updateWithoutMerge (brandId, clinicId, userId, scheduleData,start,end){
  // console.log(scheduleData,start,end,"data-sche passed")
  var splitschedule = splitScheduleByMonths(scheduleData)
    try {
      const months = getMonthsBetweenDates(start,end)
      console.log(months,splitschedule,scheduleData,"data-sche calculated")
      for (const month of months) {
        const [year, monthNumber] = month.split("-");
        const userRef = doc(db, `schedule/${brandId}/${clinicId}/${userId}/${year}/${monthNumber}`);
        // console.log("data-sche     -  ", `schedule/${brandId}/${clinicId}/${userId}/${year}/${monthNumber}`, splitschedule[month])
 
        // Update user's schedule data
        // console.log("splitschedule[month] =",splitschedule[month])
        
        if (Object.keys(scheduleData).length === 0) {
          await deleteDoc(userRef)
        } else {
          if(splitschedule[month])
            {
              await setDoc(userRef, {
                schedule: splitschedule[month] || {},
            });
            }else{
              await deleteDoc(userRef)
            }
        }
       
     
 
      }
    //console.log("data base ",brandId,clinicId,userId)
    
        //console.log(`Schedule data updated successfully for user ${userId} in clinic ${clinicId} of brand ${brandId}.`);

    } catch (error) {

        console.error("Error updating schedule data:", error);

    }

}
async function updateScheduleData(brandId, clinicId, userId, scheduleData,start,end) {
  // console.log(scheduleData,start,end,"data-sche passed")
  var splitschedule = splitScheduleByMonths(scheduleData)
    try {
      const months = getMonthsBetweenDates(start,end)
      // console.log(months,splitschedule,"data-sche calculated")
      for (const month of months) {
        const [year, monthNumber] = month.split("-");
        const userRef = doc(db, `schedule/${brandId}/${clinicId}/${userId}/${year}/${monthNumber}`);
        // console.log("data-sche     -  ", `schedule/${brandId}/${clinicId}/${userId}/${year}/${monthNumber}`, splitschedule[month])
 
        // Update user's schedule data
        if(splitschedule[month])
        {
          await setDoc(userRef, {
            schedule: splitschedule[month],
        }, { merge: true });
        }
     
 
      }
    //console.log("data base ",brandId,clinicId,userId)
    
        //console.log(`Schedule data updated successfully for user ${userId} in clinic ${clinicId} of brand ${brandId}.`);

    } catch (error) {

        console.error("Error updating schedule data:", error);

    }

}

var temp_role = async () => {

  var data =   {
    "id": "1376asdf-f32a-4dbb-8256-o90223r8h",
    "nav": false,
    "module": {
        "REST13": {
            "other": "",
            "adminaccess": true,
            "admin": false,
            "type": "collapse",
            "lock": false,
            "active": true,
            "id": "REST13",
            pos:12,
            "view": true,
            "name": "Leave Management",
            "accesstype": 2
        },
        "REST12": {
            "active": true,
            "id": "REST12",
            "route": "/venueloc",
            "view": true,
            "adminaccess": true,
            "other": "",
            "key": "help",
            pos:11,
            "type": "collapse",
            "admin": false,
            "lock": false,
            "name": "Help & support",
            "accesstype": 2
        },
        "REST09": {
            "route": "/access",
            "key": "access",
            "id": "REST09",
            "type": "collapse",
            "adminaccess": false,
            "admin": false,
            "active": true,
            "view": true,
            "lock": false,
            pos:4,
            "accesstype": 2,
            "other": "",
            "name": "Access Management"
        },
        "REST07": {
            "adminaccess": false,
            "admin": false,
            "lock": false,
            "key": "brand",
            "type": "dropdown",
            "accesstype": 2,
            pos:5,
            "name": "Employee type",
            "id": "REST07",
            "active": true,
            "service": "PVE",
            "view": true,
            "other": ""
        },
        "REST03": {
            "type": "collapse",
            "service": "PVE",
            "view": true,
            "other": "",
            "active": true,
            "name": "clinic management",
            "key": "clinic",
            "id": "REST03",
            pos:3,
            "accesstype": 2,
            "route": "/dashboard",
            "admin": false,
            "adminaccess": false,
            "lock": false
        },
        "REST08": {
            "adminaccess": false,
            "key": "contact type",
            "route": "/contact",
            "other": "",
            "admin": false,
            "lock": false,
            pos:6,
            "id": "REST08",
            "view": true,
            "active": true,
            "name": "Contract type",
            "accesstype": 2
        },
        "REST05": {
            "view": true,
            "active": true,
            "name": "Staff Management",
            "accesstype": 2,
            "other": "",
            pos:7,
            "admin": false,
            "id": "REST05",
            "lock": false,
            "adminaccess": false,
            "key": "staff",
            "route": "/staff"
        },
        "REST0101": {
            "id": "REST0101",
            "name": "Customer Details",
            "accesstype": 2,
            "admin": false,
            "adminaccess": false,
            "other": "",
            "lock": false,
            pos:2,
            "active": true,
            "type": "collapse",
            "key": "details",
            "view": true,
            "route": "/details/type=:type"
        },
        "REST10": {
            "lock": true,
            "key": "role-manage",
            "view": true,
            "id": "REST10",
            "accesstype": 1,
            "type": "collapse",
            "route": "/schedule",
            pos:9,
            "adminaccess": true,
            "name": "Scheduler",
            "active": true,
            "admin": true,
            "other": ""
        },
        "REST06": {
            "active": true,
            "accesstype": 2,
            "adminaccess": false,
            "name": "Activity Database",
            "lock": false,
            "other": "",
            "admin": false,
            pos:8,
            "id": "REST06",
            "route": "/activity",
            "key": "activity",
            "view": true
        },
        "REST11": {
            "active": true,
            "view": true,
            "type": "collapse",
            "id": "REST11",
            "route": "/details",
            "other": "",
            pos:10,
            "name": "Reports",
            "admin": false,
            "accesstype": 2,
            "lock": false,
            "adminaccess": false,
            "key": "details"
        },
        "REST01": {
            "active": false,
            "accesstype": 2,
            "key": "Customer",
            "adminaccess": false,
            "view": true,
            pos:0,
            "admin": false,
            "id": "REST01",
            "route": "/customer",
            "lock": true,
            "type": "collapse",
            "other": "",
            "name": "Customer"
        },
        "REST02": {
            "view": true,
            "key": "configuration",
            "name": "Configuration",
            "admin": false,
            "type": "dropdown",
            "accesstype": 2,
            "adminaccess": false,
            "active": true,
            pos:1,
            "items": [
                "REST0101",
                "REST08",
                "REST06",
                "REST05",
                "REST07",
                "REST09",
                "REST03"
            ],
            "other": "",
            "lock": false,
            "id": "REST02",
            "route": "/configuration"
        },
        "REST14": {
            "adminaccess": false,
            "admin": false,
            "lock": false,
            "other": "",
            "accesstype": 2,
            "route": "/privacy",
            "key": "privacy",
            "service": "PVE",
            "active": true,
            "view": true,
            "id": "PVE07",
            pos:13,
            "name": "Privacy policy",
            "type": "collapse"
        }
    },
    "type": "pages",
    "createdTime":new Date()
}
data.createdTime = new Date();
const response =await  setDoc(doc(db,"modules",data.id),data)
};



async function upload_file(file,customerid = uuidv4()) {

  try {
    const storageRef = ref(
      storage,
      `Images/${customerid + (file.type == "image/png" ? ".png" : ".jpg")}`
    );

    const uploadTask = uploadBytesResumable(storageRef, file);

  var downloadURL =  await new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Handle progress if needed
        },
        (error) => {
          // Upload failed
          reject(error);
        },
        () => {
          // Upload completed successfully
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              resolve(downloadURL); // Resolve with download URL
            })
            .catch((error) => {
              reject(error); // Reject with error if getting download URL fails
            });
        }
      );
    });

    // Upload and retrieval of download URL successful
    return downloadURL;
  } catch (error) {
    // Error occurred during upload
    console.error("Error uploading file:", error);
    return false
    throw error; // Propagate error to the caller
    
  }
}
export {
  createSupportTicket,
  updateStatus,
  get_customer,
  add_clinic,
  get_countryList,
  get_staff,
  get_contract,
  updateContract_status,
  add_contract,
  edit_contract,
  add_roles,
  get_role,
  get_roles,
  get_rolebased_users,
  updateRoleStatus,
  get_module,
  get_employeeType,
  add_staff,
  update_staff,
    add_leave,
    get_admin_leave,
    get_staff_leave,
    update_leave_status,
    update_leave,
  addCustomer,
  get_all_customer,
  get_user_details,
  create_login_session,
  write_terms,
  get_user_terms,
  updateCustomer,
  get_clinic,
  create_user,
  get_users,
  update_User,
  update_role,
  temp_role,
  get_activities,
  upload_file,
  add_shedule,
  get_shedule,
  updateScheduleData,
  get_clinic_id,
  update_clinic,
  get_user,
  get_staff_clinic,
  updateWithoutMerge,
  get_leave_details,
  get_staff_leave_pending,
  CounterUpdate,
  deleteDocument,
  deleteLeaveRecordById,
  get_notification_leave,
  calculate_Time_Passed,
  getMonthsBetweenDates,
  get_help_notification
};
