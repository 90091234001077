import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  useMaterialUIController,
  setSelecetdCustomerrec
} from "context";

import Typography from "@material-ui/core/Typography";
import Sort from "../../assets/images/icons/sort.png";
import SortWhite from "../../assets/images/icons/sortwhite.png";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Filter from "../../components/Filter";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//modal import
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { categoryType ,auth} from "backendservice/firebase";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "backendservice/firebase";
import alasql from "alasql";
import { checkDelete, createEmployeetype, updateEmployeeType, updateStatus } from "./helperFunctions/helper";
import { useAuthState } from "react-firebase-hooks/auth";
import { employeeType } from "backendservice/firebase";
import Nodata from "components/Nodata";
import SearchComponent from "components/SearchInput";
import Sortlist from "components/Sortlist";
import { deleteDocument } from "backendservice/firebasefunctions,";
import { Radio } from "@mui/material";

function Employee() {
 const [controller, dispatch] = useMaterialUIController();
  const {

  selecetdCustomer,
  selected_clinic,
  permission
} = controller;

  const { t } = useTranslation();
  //modal open hook
  const [modalopen, setmodalopen] = useState(false);

  const [name, setName] = useState("");
  const [category, setcategory] = useState(selecetdCustomer.categoryType=="oth"?"oth":"");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [brandCategory, setBrandCategory] = useState("");
  const [user] =useAuthState(auth)
  const [userData,setuserData] = useState({})
  const [data,setData] = useState([])
  const [databackup,setDatabackup] = useState([])
  const [isEditing,setIsEditing] = useState(false)
  const [editingData,setEditingData] = useState({})

 
  const handlemodalopen = () => {
    setmodalopen(true);
  };
  const handlemodalClose = () => {
    setName("");
    setcategory(selecetdCustomer.categoryType=="oth"?"oth":"")
    setmodalopen(false);
  };

  const submit = async(e)=>{
    e.preventDefault()
    if(category=="")
      {
        alert(t("Please update the required field"))
        return
      }
    setLoading(true)
    
    var temp = {
      "module": "Employee Type",
      "page":"employee ",
      "customer":selecetdCustomer.id,
      "user":user.uid,
      "userName":user.email,
      "category":category,
      "employeeType":name,
      "active":true,
      "defaultClinicId":selected_clinic.id
    }
    // console.log("data ==",temp,user)
    temp.attachCounter = 0
    await createEmployeetype(temp)
    setRefresh(!refresh)
    setLoading(false)
    handlemodalClose()
  }

  const loadData=async()=>{
    const customer = selecetdCustomer.id
    const selectedClinic = selected_clinic.id

    var datass = localStorage.getItem("user");
    if (datass == undefined || datass == "") {
      // setusername("User")
    } else {
      var json = JSON.parse(datass);
      setuserData(json)
    }
    if(customer!=="" && customer!==undefined && selecetdCustomer!=="" && selecetdCustomer!==undefined)
    {
        setLoading(true)
        try{
            var snapshot = await getDocs(query(employeeType, where("customer", "==", customer),where("defaultClinicId","==",selectedClinic)));
            // console.log("data =====>",snapshot)
            
            let employeetypeData = snapshot.docs.map((data) => ({
      ...data.data(),
      id:data.id,
      data:data.data(),
      label:data.data().employeeType,
      createdTime:data.data().raisedDate
     }))

     setData(employeetypeData)
     setDatabackup(employeetypeData)
    //  console.log(employeetypeData, "snapshot");
            setLoading(false)
        }catch(error)
        {
            setLoading(false)
            console.log("error ==>",error)
        }
    }
  }

  const handleActive=async(index)=>{
    setLoading(true);
    const tempData = [...data];
    tempData[index].data.active = !tempData[index].data.active; // Update local state immediately
    setData(tempData); // Update local state
  
    try {
      await updateStatus(tempData[index].id, index, tempData); // Asynchronously update data in Firestore
      setLoading(false);
    } catch (error) {
      console.log("Error updating status:", error);
      setLoading(false);
    }
  }
  const handleEdit = async (index) => {
    // console.log("editing");
    setIsEditing(true)
    let tempdata =[...data]
    setEditingData({name:tempdata[index].data.employeeType,index})
    setName(tempdata[index].data.employeeType)
    setcategory(tempdata[index].category || "")
    handlemodalopen()
  }
  const edit = async (e) => {
    e.preventDefault();
    if(category=="")
    {
      alert(t("Please update the required field"))
      return
    }
    setLoading(true);
    
    // Update local state immediately
    // console.log("update category -= ",category)
    const tempData = [...data];
    tempData[editingData.index].data.employeeType = name;
    tempData[editingData.index].data.category = category;
    setData(tempData); // Update local state
  
    try {
      await updateEmployeeType(tempData[editingData.index].id, tempData[editingData.index].data, name); // Asynchronously update data in Firestore
      setEditingData({}); // Clear editing data
      setName(''); // Clear name state
      setIsEditing(false); // Reset editing state
      setLoading(false);
      handlemodalClose(); // Close modal
      setRefresh(!refresh)
    } catch (error) {
      console.log("Error updating employee type:", error);
      setLoading(false);
    }
  }
  const handleDelete = async(id)=>{
    setLoading(true)
    
    if(await checkDelete(id))
      {
        await deleteDocument(process.env.REACT_APP_EMPLOYEE,id)
        alert(t("delete.success"))
      }else{
        alert(t("delete.restricted"))
      }
      setLoading(false)
    setRefresh(!refresh)
  }
  const isButtonDisabled = (moduleId) => {
    return !(
      !permission.module ||
      !permission.module[moduleId] ||
      permission.module[moduleId].accesstype === 2
    );
  };
  useEffect(() => {
    if (selected_clinic.id && selecetdCustomer.id) {
      // console.log("trig inside");
      loadData().then(() => setLoading(false));
    }
  }, [refresh,selecetdCustomer, selected_clinic]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loader loading={loading} />
      <MDBox pt={4} pb={3} className="xl-container">
        <Box>
          <Box>
            <MDBox className="page-heading">
              <MDTypography
                variant="h5"
                gutterBottom
                sx={{ pb: "8px", display: "flex" }}
              >
                {t("Employee types")}
              </MDTypography>
            </MDBox>

            <Box>
              <Box
                sx={{
                  margin: "10px 0 0 0",
                }}
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
               <SearchComponent searchList = {databackup} onSearch={(e)=>setData(e)} />
                
                  <Box className="filter-mobile">
                    <MDBox
                      color="text"
                      px={0}
                      sx={{ display: { xs: "block", sm: "flex" } }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                        <Sortlist onSort={(e)=>setData(e)} sortlist={databackup}/>
                        <Filter   onFilter={(e)=>{setData(e)}} filterlist={databackup} brandId ={selecetdCustomer.id} module = "employeeType"/>
                
                      </Box>
                    </MDBox>
                  </Box>
                </MDBox>
              </Box>
              <MDBox
                sx={{
                  marginTop: "20px",
                }}
              >
               {data.length == 0 ? <Nodata /> : 
                <List className="datatable">
                {data.map((data,index) => (
                  <ListItem>
                  <Box className="list-content">
                    <Box className="left-content">
                      <label className="heading">{data.data.employeeType}</label>
                    </Box>
                 
                    <Box className="blue-bg">
                    <MDButton
                  className="link-dot"
                  disabled={isButtonDisabled("REST07")}
                onClick={(e)=>handleDelete(data.id)}
                >
                 <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 13 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.151367 3.59384C0.151367 3.32412 0.370019 3.10547 0.639739 3.10547H12.3607C12.6304 3.10547 12.849 3.32412 12.849 3.59384C12.849 3.86356 12.6304 4.08221 12.3607 4.08221H0.639739C0.370019 4.08221 0.151367 3.86356 0.151367 3.59384Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19829 1.47674C4.98241 1.47674 4.77538 1.5625 4.62274 1.71515C4.47009 1.86779 4.38433 2.07482 4.38433 2.2907V3.59302C4.38433 3.86274 4.16568 4.0814 3.89596 4.0814C3.62624 4.0814 3.40759 3.86274 3.40759 3.59302V2.2907C3.40759 1.81577 3.59625 1.3603 3.93207 1.02448C4.26789 0.688662 4.72337 0.5 5.19829 0.5H7.80294C8.27786 0.5 8.73333 0.688662 9.06915 1.02448C9.40497 1.3603 9.59364 1.81577 9.59364 2.2907V3.59302C9.59364 3.86274 9.37498 4.0814 9.10526 4.0814C8.83554 4.0814 8.61689 3.86274 8.61689 3.59302V2.2907C8.61689 2.07482 8.53114 1.86779 8.37849 1.71515C8.22584 1.5625 8.01881 1.47674 7.80294 1.47674H5.19829ZM1.94247 3.10465C2.21219 3.10465 2.43085 3.3233 2.43085 3.59302V12.7093C2.43085 12.9252 2.5166 13.1322 2.66925 13.2849C2.82189 13.4375 3.02893 13.5233 3.2448 13.5233H9.75643C9.9723 13.5233 10.1793 13.4375 10.332 13.2849C10.4846 13.1322 10.5704 12.9252 10.5704 12.7093V3.59302C10.5704 3.3233 10.789 3.10465 11.0588 3.10465C11.3285 3.10465 11.5471 3.3233 11.5471 3.59302V12.7093C11.5471 13.1842 11.3585 13.6397 11.0226 13.9755C10.6868 14.3113 10.2314 14.5 9.75643 14.5H3.2448C2.76988 14.5 2.31441 14.3113 1.97858 13.9755C1.64276 13.6397 1.4541 13.1842 1.4541 12.7093V3.59302C1.4541 3.3233 1.67275 3.10465 1.94247 3.10465Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19833 6.35938C5.46805 6.35938 5.68671 6.57803 5.68671 6.84775V10.7547C5.68671 11.0244 5.46805 11.2431 5.19833 11.2431C4.92861 11.2431 4.70996 11.0244 4.70996 10.7547V6.84775C4.70996 6.57803 4.92861 6.35938 5.19833 6.35938Z" fill="#16273C"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80283 6.35938C8.07255 6.35938 8.2912 6.57803 8.2912 6.84775V10.7547C8.2912 11.0244 8.07255 11.2431 7.80283 11.2431C7.5331 11.2431 7.31445 11.0244 7.31445 10.7547V6.84775C7.31445 6.57803 7.5331 6.35938 7.80283 6.35938Z" fill="#16273C"/>
                            </svg>
                
                </MDButton>
                      <MDButton className="link-dot"
                            disabled={isButtonDisabled("REST07")}
                      onClick={() => handleEdit(index)}>  
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="18"
                          viewBox="0 0 17 15"
                          fill="none"
                        >
                          <path
                            d="M12.4649 1.08492C12.6572 0.899475 12.8855 0.752375 13.1367 0.652015C13.3879 0.551655 13.6572 0.5 13.9291 0.5C14.201 0.5 14.4703 0.551655 14.7215 0.652015C14.9727 0.752375 15.201 0.899475 15.3933 1.08492C15.5856 1.27036 15.7381 1.49051 15.8422 1.7328C15.9462 1.97509 15.9998 2.23478 15.9998 2.49703C15.9998 2.75928 15.9462 3.01897 15.8422 3.26126C15.7381 3.50355 15.5856 3.7237 15.3933 3.90915L5.50995 13.4409L1.4834 14.5L2.58155 10.6167L12.4649 1.08492Z"
                            stroke="#16273C"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </MDButton>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
         className={isButtonDisabled("REST07")? "disabled" :""}

                        >
                          <label className="active-text">{data.data.active?t("Active"):t("Inactive")}</label>
                          <Switch
                            disabled={isButtonDisabled("REST07")}
                           checked={data.data.active?data.data.active:false}
                           onChange={() => handleActive(index)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
                ))}
                
                
              </List>}
              </MDBox>
              <Box
                className="navigation-link"
              >
                  <MDButton
                  className="primary-btn btn xl"
                  onClick={() => handlemodalopen()}
                  // onClick={() => navigate("/employee")}
                  disabled={isButtonDisabled("REST07")}

                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.36739 1.86739C7.36739 1.1122 6.75519 0.5 6 0.5C5.24481 0.5 4.63261 1.1122 4.63261 1.86739V5.13261H1.36739C0.612202 5.13261 0 5.74481 0 6.5C0 7.25519 0.612202 7.86739 1.36739 7.86739H4.63261V11.1326C4.63261 11.8878 5.24481 12.5 6 12.5C6.75519 12.5 7.36739 11.8878 7.36739 11.1326V7.86739H10.6326C11.3878 7.86739 12 7.25519 12 6.5C12 5.74481 11.3878 5.13261 10.6326 5.13261H7.36739V1.86739Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp;&nbsp; {t("Add employee type")}
                </MDButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </MDBox>

      {/* Modal mm-04 */}
      <Modal
        open={modalopen}
        onClose={handlemodalClose}
        aria-labelledby="modal-modal-title"
        className="cs-modal xs"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-dialog" >
          <Box className="modal-content" component="form"
           onSubmit={(e)=>(isEditing?edit(e):submit(e))}>
        
            <Box className="modal-body" >
              <FormGroup sx={{ mb: 2, mr: 2, width: "100%" }}>
                <InputLabel className="formlabel">{t("Employee type")}<strong style={{color:"red"}}> *</strong></InputLabel>
                <TextField
                  className="forminput"
                  placeholder={t("Enter name")}
                  variant="outlined"
                  required
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </FormGroup>
              <Box style={{ display: "flex" }}>
                    
                   {selecetdCustomer.categoryType=="clinic" && <FormGroup sx={{ mb: 2, ml: 1, mr: 8 }}>
                    <InputLabel className="formlabel">{t("Type")}<strong style={{color:"red"}}> *</strong></InputLabel>
                    <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            display: "flex",
                            textAlign: "center",
                          }}
                          className="cs-radio"
                          label={t("ASV")}
                          name = "category"
                          value="asv"
                          disabled = {selecetdCustomer.categoryType!=="clinic"}
                          control={
                            <Radio
                            checked={category == "asv"}
                              onChange={(e) =>
                                
                                setcategory(
                                 e.target.value
                                )
                            
                              }
                            />
                            
                          }
                        />
                    
                        <FormControlLabel
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            ml: 6,
                          }}
                          className="cs-radio"
                          label={t("Vétérinaire")}
                          name = "category"
                          disabled = {selecetdCustomer.categoryType!=="clinic"}
                          value="vet"
                          control={
                            <Radio
                              checked={category == "vet"}
                              onChange={(e) =>
                                
                                setcategory(
                                 e.target.value
                                )
                              }
                            />
                          }
                        />
                        
                        <FormControlLabel
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            ml: 6,
                          }}
                          className="cs-radio"
                          label={t("Other")}
                          name = "category"
                          value="oth"
                          // disabled = {selecetdCustomer.categoryType!=="oth"}
                          control={
                            <Radio
                              checked={category == "oth"}
                              onChange={(e) =>
                                
                                setcategory(
                                 e.target.value
                                )
                              }
                            />
                          }
                        />
                        
                      </Box>
                    </FormGroup>}
                    <div
                      style={{
                        backgroundColor: "red",
                        height: "100%",
                        width: "2px",
                      }}
                    ></div>
                  </Box>
            </Box>
            <Box className="modal-footer space-around">
           
              <MDButton
                className="secondary-btn white"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                onClick={() => handlemodalClose()}
              >
                {t("Cancel")}
              </MDButton>
              <MDButton
                className="secondary-btn"
                sx={{
                  padding: "11px 30px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  minWidth: "160px",
                }}
                color="dark"
                type="submit"
              >
                {isEditing?t("Update"):t("Submit")}
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default Employee;
